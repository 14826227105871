










  import { Component, Vue, Prop } from 'nuxt-property-decorator'

  @Component
  export default class OneCategoryBox extends Vue {
    @Prop({
      required: true,
      type: String,
    })
    readonly image!: string

    @Prop({
      required: true,
      type: String,
    })
    readonly text!: string
  }
